export class ClassBuilder {
  private classList: Record<string, boolean> = {}

  constructor(rawClassList?: Record<string, boolean>) {
    if (rawClassList) {
      Object.entries(rawClassList).forEach(([key, value]) => this.add(key, value))
    }
  }

  get classes(): Record<string, boolean> {
    return this.classList
  }

  add(classes: string, condition: boolean) {
    classes.split(' ').forEach(c => (this.classList[c] = condition || this.classList[c]))
  }

  static parse(rawClassList: Record<string, boolean>): Record<string, boolean> {
    return new ClassBuilder(rawClassList).classList
  }
}
